export default {
    init() {
        const modalTriggers = document.querySelectorAll('.js-modal-trigger');

        modalTriggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => this.openModal(event, trigger));
        });

        // Check for modals that should open by default
        const defaultOpenModals = document.querySelectorAll('.modal[data-open-by-default]');
        defaultOpenModals.forEach(modal => this.openModal(null, modal, true));
    },

    openModal(event, trigger, isDefault = false) {
        if (event) event.preventDefault();

        let modal;
        if (isDefault) {
            modal = trigger; // If called for default opening, trigger is actually the modal itself
        } else {
            const parentSelector = trigger.getAttribute('data-parent');
            modal = parentSelector ? document.querySelector(parentSelector) : trigger.nextElementSibling;
        }

        if (!modal) return;

        modal.classList.add('modal-is-open');

        const backdrop = modal.querySelector('.modal-backdrop');
        const closeButtons = modal.querySelectorAll('.js-modal-trigger-close');

        if (backdrop) {
            backdrop.addEventListener('click', () => this.closeModal(modal));
        }

        closeButtons.forEach(closeButton => {
            // Add event listeners to all close buttons inside the modal
            closeButton.addEventListener('click', () => this.closeModal(modal));
        });
    },

    closeModal(modal) {
        modal.classList.remove('modal-is-open');
    },
};
