export default class FunctionalRolesModalHandler {
    constructor(selector = '.js-functional-roles-modal') {
        const popup = document.querySelector(selector);
        if (!popup) return;

        const closeButtons = popup.querySelectorAll('.js-modal-trigger-close');

        closeButtons.forEach(button => {
            button.addEventListener('click', async () => {
                const url = Routing.generate('user_function_roles_modal_shown_async')

                try {
                    await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ message: true })
                    });
                } catch (error) {
                    console.error('Error:', error);
                }
            });
        });
    }
}
